<template>
  <div class="hello">
    <ElButton class="show" @click="showPanel = true">显示面板</ElButton>

    <ElCard class="from" v-if="showPanel">
      <template #header>
        <div class="card-header">
          <span>操控面板</span>
          <el-button class="button" text @click="showPanel = false">隐藏面板</el-button>
        </div>
      </template>
      <ElForm>
        <ElFormItem label="横向(row)">
          <ElInputNumber :min="20" :step="5" v-model="queryPramers.row" />
        </ElFormItem>
        <ElFormItem label="竖向(col)">
          <ElInputNumber :min="20" :step="5" v-model="queryPramers.col" />
        </ElFormItem>
        <ElFormItem label="大小">
          <ElSlider
            style="width: 320px"
            v-model="size"
            :min="1"
            :max="100"
            show-input
          ></ElSlider>
        </ElFormItem>
        <ElFormItem label="元素">
          <ElRadioGroup v-model="radio" size="large">
            <ElRadio v-for="item in radioArr" :key="item.key" :label="item.key">
              <sapn :style="'display:inline-block;width:10px;height:10px;margin-right:10px;background:' + item.background"></sapn>{{ item.text }}
            </ElRadio>
          </ElRadioGroup>
        </ElFormItem>
        <ElFormItem>
          <ElButton type="primary" @click="draw">绘制</ElButton>
          <ElButton type="primary" @click="reset">重置</ElButton>
          <ElButton class="copybox" type="success" @click="ok">生成数据</ElButton>
        </ElFormItem>
      </ElForm>
    </ElCard>

    <div class="table" v-if="showTable">
      <template v-for="cols in queryPramers.col" :key="cols">
        <ul class="ul" :posi="cols">
          <li
            v-for="rows in queryPramers.row"
            :key="rows"
            :class="'li dom' + cols + '-' + rows"
            :style="
              'width: ' +
              size +
              'px;height:' +
              size +
              'px;background:' +
              computedMsg('dom' + cols + '-' + rows)
            "
            @click="itemChange"
            :posi="cols + '-' + rows"
          ></li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Clipboard from "clipboard";
import {
  ElForm,
  ElFormItem,
  ElInputNumber,
  ElButton,
  ElSlider,
  ElCard,
  ElRadioGroup,
  ElRadioButton,
  ElMessage,
  ElRadio,
} from "element-plus";

@Options({
  props: {
    msg: String,
  },
  components: {
    ElForm,
    ElFormItem,
    ElInputNumber,
    ElButton,
    ElSlider,
    ElCard,
    ElRadioGroup,
    ElRadioButton,
    ElRadio,
  },
})
export default class HelloWorld extends Vue {
  queryPramers = {
    row: 0,
    col: 0,
  };
  size: number = 50;

  radio: any = 1;

  showTable: boolean = false;

  showPanel: boolean = true;

  resultJson: Array<any> = [];

  radioArr = [
    { key: -1, text: "角色", background: "#24ff18" },
    { key: -2, text: "敌人", background: "#ff1818" },
    { key: 1, text: "实体墙", background: "#515151" },
    { key: 2, text: "可击毁墙", background: "#ffb827" },
    { key: 3, text: "河流", background: "#41e3ff" },
    { key: 4, text: "双炮台", background: "#dbff2b" },
    { key: 5, text: "单炮台", background: "#a9a900" },
    { key: 6, text: "箱子", background: "#13937b" },
  ];

  // 绘制
  draw = () => {
    this.showTable = true;
    let { col, row } = this.queryPramers;
    this.queryPramers = {
      col: Number(row),
      row: Number(col),
    };
  };
  // 重置
  reset = () => {
    this.showTable = false;
    this.queryPramers = {
      col: 0,
      row: 0,
    };
  };
  // 点击某个格子
  itemChange = (e: Event | any) => {
    let dom = e.target;
    let _radio: any = this.radioArr.filter((v) => v.key == this.radio);
    let { key, background } = _radio[0];
    if (dom.key == undefined) {
      dom.key = key;
      dom.style.background = background;
    } else {
      delete dom.key;
      dom.style.background = "#fff";
    }
  };
  // 生成数据
  ok = () => {
    let dom = document.querySelector(".table");
    let y = dom?.children || [];
    this.resultJson = [];
    let newArr: any = [];
    for (let i = 0; i < y.length; i++) {
      let arr = [];
      let x: any = y[i]?.children;
      for (let j = 0; j < x.length; j++) {
        if (x[j]?.key == undefined) {
          arr.push(0);
        } else {
          arr.push(x[j].key);
        }
      }
      newArr.push(arr);
    }
    this.resultJson = newArr;
    console.log("resultJson 结果：", JSON.stringify(newArr));
    this.copy(JSON.stringify(newArr));

    let _newArr = JSON.parse(JSON.stringify(newArr));
    for (let i = 0; i < _newArr.length; i++) {
      _newArr[i] = _newArr[i].join(",");
    }
    let str = _newArr.join("/");
    console.log("resultStr 结果：", str);
  };
  // 更改节点时 不清空颜色
  computedMsg(e: string) {
    let dom: any = document.querySelector("." + e);
    if (dom?.key == undefined) return;
    let item = this.radioArr.filter((v) => v.key == dom?.key);
    return item.length != 0 ? item[0].background : "#fff";
  }
  // 复制
  copy(text: string) {
    let clipboard = new Clipboard(".copybox", {
      text() {
        return text;
      },
    });
    clipboard.on("success", (e: Event) => {
      console.log("复制成功");
      ElMessage({
        message: "生成成功，已复制",
        type: "success",
      });
      // 释放内存
      clipboard.destroy();
    });
    clipboard.on("error", (e: Event) => {
      // 不支持复制
      console.log("该浏览器不支持自动复制");
      ElMessage({
        message: "该浏览器不支持自动复制",
        type: "error",
      });
      // 释放内存
      clipboard.destroy();
    });
  }
}
</script>

<style lang="less" scoped>
.hello {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  .show {
    position: fixed;
    top: 15px;
    left: 15px;
  }

  .from {
    position: fixed;
    top: 0;
    left: 0;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    /deep/ .el-card__header {
      padding: 0px 15px;
    }
  }
  .table {
    .ul {
      display: block;
      list-style: none;
      display: flex;

      .li {
        border: 1px solid #838383;
      }
    }
  }
}
</style>
