import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "hello" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = {
  key: 1,
  class: "table"
}
const _hoisted_4 = ["posi"]
const _hoisted_5 = ["posi"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElButton = _resolveComponent("ElButton")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ElInputNumber = _resolveComponent("ElInputNumber")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElSlider = _resolveComponent("ElSlider")!
  const _component_sapn = _resolveComponent("sapn")!
  const _component_ElRadio = _resolveComponent("ElRadio")!
  const _component_ElRadioGroup = _resolveComponent("ElRadioGroup")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElCard = _resolveComponent("ElCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElButton, {
      class: "show",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPanel = true))
    }, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [
        _createTextVNode("显示面板")
      ])),
      _: 1
    }),
    (_ctx.showPanel)
      ? (_openBlock(), _createBlock(_component_ElCard, {
          key: 0,
          class: "from"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[9] || (_cache[9] = _createElementVNode("span", null, "操控面板", -1)),
              _createVNode(_component_el_button, {
                class: "button",
                text: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPanel = false))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("隐藏面板")
                ])),
                _: 1
              })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_ElForm, null, {
              default: _withCtx(() => [
                _createVNode(_component_ElFormItem, { label: "横向(row)" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElInputNumber, {
                      min: 20,
                      step: 5,
                      modelValue: _ctx.queryPramers.row,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.queryPramers.row) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ElFormItem, { label: "竖向(col)" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElInputNumber, {
                      min: 20,
                      step: 5,
                      modelValue: _ctx.queryPramers.col,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.queryPramers.col) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ElFormItem, { label: "大小" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElSlider, {
                      style: {"width":"320px"},
                      modelValue: _ctx.size,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.size) = $event)),
                      min: 1,
                      max: 100,
                      "show-input": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ElFormItem, { label: "元素" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElRadioGroup, {
                      modelValue: _ctx.radio,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.radio) = $event)),
                      size: "large"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioArr, (item) => {
                          return (_openBlock(), _createBlock(_component_ElRadio, {
                            key: item.key,
                            label: item.key
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_sapn, {
                                style: _normalizeStyle('display:inline-block;width:10px;height:10px;margin-right:10px;background:' + item.background)
                              }, null, 8, ["style"]),
                              _createTextVNode(_toDisplayString(item.text), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ElFormItem, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElButton, {
                      type: "primary",
                      onClick: _ctx.draw
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("绘制")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_ElButton, {
                      type: "primary",
                      onClick: _ctx.reset
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("重置")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_ElButton, {
                      class: "copybox",
                      type: "success",
                      onClick: _ctx.ok
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("生成数据")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showTable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queryPramers.col, (cols) => {
            return (_openBlock(), _createElementBlock("ul", {
              key: cols,
              class: "ul",
              posi: cols
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queryPramers.row, (rows) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: rows,
                  class: _normalizeClass('li dom' + cols + '-' + rows),
                  style: _normalizeStyle(
              'width: ' +
              _ctx.size +
              'px;height:' +
              _ctx.size +
              'px;background:' +
              _ctx.computedMsg('dom' + cols + '-' + rows)
            ),
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.itemChange && _ctx.itemChange(...args))),
                  posi: cols + '-' + rows
                }, null, 14, _hoisted_5))
              }), 128))
            ], 8, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}